<template>
    <modal :show.sync="tmp_visible" modal-classes="modal-dialog-centered modal-sm">
        <h4 slot="header" class="text-muted text-center px-4">修改登录密码</h4>
        <card type="secondary" shadow header-classes="bg-white" body-classes="px-lg-3" class="border-0" v-loading="loading">
            <template>
            <validation-observer v-slot="{handleSubmit}" ref="changePasswordFormValidator">
            <form role="changePasswordForm" v-loading="loading" @submit.prevent="handleSubmit(changePassword)">
                <base-input alternative type="password" placeholder="旧登录密码" v-model="model.password" name="Password" required></base-input>
                <base-input alternative type="password" placeholder="新密码" v-model="model.newPassword" name="newpassword" 
                    :rules="{ required: true, min: 8, max: 45 }"></base-input>
                <base-input alternative type="password" placeholder="确认新密码" v-model="model.newPasswordConfirm" name="newpasswordconfirm" 
                    :rules="{ required: true, min: 8, max: 45 }"></base-input>
                <div class="text-center">
                <base-button nativeType="submit" type="success" class="my-4">保存</base-button>
                <base-button type="light" @click="tmp_visible=false">取消</base-button>
                </div>
            </form>
            </validation-observer>
            </template>
        </card>
    </modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            description: "Show or hide modal",
            default: true
        },
    },
    data() {
        return {
            loading: false,
            tmp_visible: this.visible,
            model: {
                password: '',
                newPassword: '',
                newPasswordConfirm: ''
            }
        }
    },
    watch: {
        tmp_visible: function(v) {
            this.$emit("update:visible", v);
        },
        visible: function(v) {
            this.tmp_visible = v
        }
    },
    methods: {
        changePassword() {
            if(this.model.newPassword !== this.model.newPasswordConfirm) {
                alert('两次新密码输入不一致.');
            }
            this.loading = true;
            this.axios.put('changepassword', this.model).then((res) => {
                if (res.data.errcode == 0) {
                    this.model.password = '';
                    this.model.newPassword = '';
                    this.model.newPasswordConfirm = '';
                    this.$notifyOK('操作成功', '您已经成功修改登录密码.');
                    this.visible = false;
                    this.$refs.changePasswordFormValidator.reset();
                } 
                else if (res.data.errcode == 60401) {
                    this.model.password = '';
                    this.$notifyErr('操作失败', '旧密码不正确.');
                }
                else {
                    this.$notifyErr('操作失败', res.data.errmsg);
                }
                this.loading = false;
            })
            .catch((e) => {
                this.$notifyErr('操作失败', e.message);
                this.loading = false;
            });
        }
    }
};
</script>